// src/SubdomainApp.js
import React, { Fragment, Suspense, lazy } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";

const LoggedOutSubdomainComponent = lazy(() => import("./logged_out_subdomain/components/Main"));

function SubdomainApp() {
  console.log("Rendering SubdomainApp component");

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Pace color={theme.palette.primary.light} />
        <Suspense fallback={<Fragment />}>
          <Routes>
            <Route path="/" element={<LoggedOutSubdomainComponent />} />
            <Route path="/*" element={<LoggedOutSubdomainComponent />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default SubdomainApp;
